//1.FONT
@import url(https://fonts.bunny.net/css?family=chivo:700|manrope:400,500,700,800|shippori-mincho:700,800);
$font-manrope: 'Manrope', sans-serif;
$font-shippori: 'shippori-mincho', sans-serif;
$font-chivo: 'chivo', sans-serif;
$font-3xs: 8px;
$font-2xs: 10px;
$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 24px;
$font-3xl: 30px;
//2.COLOR
// New Ecom
$color-brand-1: #024430;
$color-brand-1-hover: #066a4c;
$color-brand-2: #FFE7BB;
$color-brand-3: #AFEAE4;
$color-brand-4: #E8B0E8;
$color-success: #06D6A0;
$color-danger: #EF476F;
$color-warning: #FFD166;
$color-mutted: #CFDEE3;
$color-info: #28A7E6;
$color-gray-50: #ECF1F2;
$color-gray-60: #E0F1F4;
$color-gray-80: #CDE2E7;
$color-gray-100: #B9CAD0;
$color-gray-200: #8EA4AC;
$color-gray-300: #6A838C;
$color-gray-400: #4E6770;
$color-gray-500: #3D565F;
$color-gray-600: #1F3C45;
$color-gray-700: #0E2932;
$color-gray-800: #122830;
$color-gray-900: #071920;
$color-white: #fff;
$color-black: #414357;
$color-blue: #006D77;
// End New

//3.Boxshadow
$box-shadow-1: 0px 0px 30px 0px rgba(14, 41, 50, 0.08);
$box-shadow-1-1: 14px 14px 36px rgba(153, 153, 153, 0.22);
$box-shadow-2: 0px 0px 20px 0px rgba(14, 41, 50, 0.17);
$box-shadow-3: 9px 11px 23px 0px rgba(6, 61, 79, 0.12);
$box-shadow-4: 4px 6px 23px rgba(228, 92, 35, 0.1);
$box-shadow-5: 4px 4px 40px rgba(16, 24, 40, 0.1);
$box-shadow-inner: inset 0px 40px 80px #FCFCFC;
$box-shadow-6: 0px 4px 4px rgba(0, 0, 0, 0.25);

//5. Background
// New
$background-white: #ffffff;
$background-bg-1: #EFE9FF;
$background-bg-2: #FFEADE;
$background-bg-3: #FDFFD4;
$background-bg-4: #D1ECF7;
$background-bg-5: #DFF9F3;
$background-bg-7: #FFEFD1;
$background-bg-8: #EBF5F8;
$background-bg-9: #F9FAF5;
$background-bg-10:#B7DDEC;
// End new
// 6. text-shadow
$text-shadow: 0px 4px 4px rgba(0,0,0,0.25);

// 7. breakpoints
$grid-breakpoints: ( xs: 0,
xxsm: 350px,
xssm: 450px,
xsm: 500px,
sm: 576px,
smd: 650px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px);